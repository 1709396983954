import React from 'react'
import Space from '../media/space.svg'
// import Banner from '../components/banner'
export default function () {
  return (
   <section className="text-white  mx-auto min-h-[30rem] bg-bjjblue pt-11 pb-11">
     <div className='container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-4 mx-auto  p-7 sm:p-11'>
        <div>
          <h3 className='text-xl'>NFT's</h3>
           <span className="font-thin">+</span><br/>
          <p>What are NFTs?</p><br/>
          <p>Why do NFTs have value?</p><br/>
          <p>How are NFTs created?</p><br/>
          <p>How can I buy NFT?</p><br/>
          
        </div>
        <div>
          <h3 className='text-xl'>Blockchain</h3>
           <span className="font-thin">+</span><br/>
          <p>What exactly is blockchain?</p><br/>
          <p>What is an example of blockchain?</p><br/>

        </div>
     </div>
     {/* <Banner /> */}
   </section>
  )
}
